<template>
  <el-card class="body" style="margin-top: 10px;">
    <div class="itemPut">
      <div style="margin: 20px"></div>
      <el-form label-width="150px" :label-position="labelPosition" ref="inserform">
        <el-form-item label="角色名称：" prop="name">
          <el-input v-model.trim="editForm.name" disabled></el-input>
        </el-form-item>
        <el-form-item label="角色名称：" prop="code">
          <el-input v-model.trim="editForm.code" disabled></el-input>
        </el-form-item>
        <el-form-item label="角色描述：" prop="remark">
          <el-input type="textarea" v-model.trim="editForm.remark"></el-input>
        </el-form-item>
        <div style="margin-top: 40px; margin-left: 70px; font-weight: 700">权限设置</div>
        <div class="across"></div>
        <el-form-item label="角色管理授权菜单：" style="margin-top: 20px" prop="permsIds">
          <el-tree :data="data" show-checkbox node-key="id"
            ref="rootTree" :default-expanded-keys="[1, 2, 3]" :default-checked-keys="[0]" :props="defaultProps" @check="currentChecked">
          </el-tree>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <router-link to="/role/list"><el-button>取 消</el-button></router-link>
        <el-button type="primary" style="margin-left: 50px" @click="editadd()">提 交</el-button>
      </div>
    </div>
  </el-card>
</template>
<script>
import { permissions } from "@/api/permission";
import { roleDetail, roleEdit } from "@/api/role";
export default {
  data() {
    return {
      labelPosition: "right",
      editForm: { permsIds: [] },
      data: [], //树形菜单
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  created() {
    this.list();
  },

  methods: {
    // 获取菜单权限
    list() {
      // 获取选中树形
      let id = this.$route.query;
      permissions({ roleId: id.roleid }).then((res) => {
        this.data = res.data;
        let that = this;
        let arr = [];
        setTimeout(function () {
          res.data.forEach((value) => {
            // 获取父节点选中下子选中
            if (value.checked == true) {
              that.$refs.rootTree.setChecked(value, true, false);
              arr.push(value.id);
              if (value.children == null) {
                return;
              }
              value.children.forEach((res) => {
                if (res.checked == true) {
                  that.$refs.rootTree.setChecked(res, true, false);
                  arr.push(res.id);
                  if (res.children == null) {
                    return;
                  }
                  res.children.forEach((asg) => {
                    if (asg.checked == true) {
                      that.$refs.rootTree.setChecked(asg, true, false);
                      arr.push(asg.id);
                    }
                  });
                }
              });
            }

            //获取父节点不选中子节点选中
            if (value.checked == false) {
              if (value.children == null) {
                return;
              }
              value.children.forEach((res) => {
                if (res.checked == true) {
                  that.$refs.rootTree.setChecked(res, true, false);
                  arr.push(res.id);
                  if (res.children == null) {
                    return;
                  }
                  res.children.forEach((asc) => {
                    if (asc.checked == true) {
                      that.$refs.rootTree.setChecked(asc, true, false);
                      arr.push(asc.id);
                    }
                  });
                }
                if (res.checked == false) {
                  if (res.children == null) {
                    return;
                  }
                  res.children.forEach((reg) => {
                    if (reg.checked == true) {
                      that.$refs.rootTree.setChecked(reg, true, false);
                      arr.push(reg.id);
                    }
                  });
                }
              });
            }
          });
        });
        this.editForm.permsIds = arr;
        //获取选中
      });
      //获取详情
      roleDetail({ id: id.roleid }).then((res) => {
        this.editForm = res.data;
        console.log(this.editForm);
      });
    },
    // 选中的节点
    currentChecked(nodeObj, SelectedObj) {
      let arr1 = SelectedObj.checkedKeys
      let arr2 = SelectedObj.halfCheckedNodes
      arr2.forEach(item=>{
        arr1.push(item.id)
      })
      this.editForm.permsIds=arr1
    },
    //编辑角色
    editadd() {
      roleEdit(this.editForm).then(() => {
        this.$router.push("/role/list");
      });
    },
  },
};
</script>
<style  lang="less" scoped>
@import "../../../assets/css/overall.less";
</style>
